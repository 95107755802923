import React, { Component } from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import "./App.scss";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import logo from "./logo.png";
import qrcode from "./qrcode.png";
import banner from "./banner.jpg";

const appId = 1000;
const baseUrl = `${
  window.location.href.indexOf("http://localhost") === 0
    ? "https://www.shopzhenpin.com"
    : ""
}/${appId}`;

SwiperCore.use([Pagination]);

const strToTime = (str, tzoffset = -1) => {
  if (!str) return 0;
  const strs = str.split(" ");
  const d = strs[0].split("-");
  const t = strs[1].split(":");
  const dt = new Date(
    parseInt(d[0], 10),
    parseInt(d[1], 10) - 1,
    parseInt(d[2], 10),
    parseInt(t[0], 10),
    parseInt(t[1], 10),
    parseInt(t[2], 10),
    0
  );
  return +dt + (tzoffset * 60 - dt.getTimezoneOffset()) * 60000;
};

const getCountDown = (ts) => {
  if (!ts) return;
  let d = +new Date();
  let diff = Math.floor((ts - d) / 1000);

  if (diff < 0) {
    return {
      dpart: 0,
      hpart: "00",
      mpart: "00",
      spart: "00",
      diff: 0,
    };
  }

  const dpart = (diff - (diff % 86400)) / 86400;
  diff -= dpart * 86400;
  const hpart = (diff - (diff % 3600)) / 3600;
  diff -= hpart * 3600;
  const mpart = (diff - (diff % 60)) / 60;
  diff -= mpart * 60;
  const spart = diff;
  return {
    dpart: dpart,
    hpart: ("0" + hpart).slice(-2),
    mpart: ("0" + mpart).slice(-2),
    spart: ("0" + spart).slice(-2),
    diff: 1,
  };
};

class App extends Component {
  state = {
    current: 0,
    homepage: { ads: [], activity: [] },
    ongoingacts: [],
    comingacts: [],
  };

  intx = null;

  componentDidMount() {
    if (this.intx) {
      clearInterval(this.intx);
    }

    this.intx = setInterval(this.showCountDown, 1000);

    this.loadHomePage();
  }

  async loadHomePage() {
    const res = await axios.get(`${baseUrl}/mall/listmainpagenew`, {
      headers: {
        "x-env": "pc",
      },
    });

    const { activity } = res.data.data;

    const nowdate = +new Date();
    activity.forEach((act) => {
      act.startts = strToTime(act.startdate);
      act.endts = strToTime(act.enddate);
      act.inact = nowdate >= act.startts && nowdate <= act.endts;
    });

    const ongoingacts = activity
      .filter((act) => act.inact)
      .sort((a, b) => a.endts - b.endts);
    const comingacts = activity
      .filter((act) => !act.inact && nowdate < act.startts)
      .sort((a, b) => a.startts - b.startts);

    res.data.data.ads = [{ picurl: banner }];

    const ext = { ongoingacts, comingacts };
    this.setState({
      homepage: res.data.data,
      ...ext,
    });
  }

  showCountDown = () => {
    const { ongoingacts, comingacts } = this.state;
    const acts = [...ongoingacts, ...comingacts];
    const cds = {};
    acts.forEach((act) => {
      const cd = getCountDown(act.inact ? act.endts : act.startts);
      cds[act.month] = cd;
    });
    this.setState({
      countdowns: cds,
    });
  };

  render() {
    const {
      homepage,
      current,
      ongoingacts,
      comingacts,
      countdowns = {},
    } = this.state;
    const { ads = [] } = homepage;
    return (
      <div className="App">
        <div className="tips">新会员注册首单返现，欧洲极速直邮，包税包清关</div>
        <Swiper className="banners" pagination={{ clickable: true }}>
          {ads.map((ad) => (
            <SwiperSlide key={ad.picurl}>
              <img className="banner" alt="" src={ad.picurl}></img>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="tabs">
          <div
            className={`tab ${current === 0 ? "current" : ""}`}
            onClick={() => {
              this.setState({ current: 0 });
            }}
          >
            臻选特卖
          </div>
          <div
            className={`tab ${current === 1 ? "current" : ""}`}
            onClick={() => {
              this.setState({ current: 1 });
            }}
          >
            即将开始
          </div>
        </div>
        <div className="acts">
          {(current === 0 ? ongoingacts : comingacts).length === 0 ? (
            <div className="empty">暂无，请稍后关注...</div>
          ) : null}
          {(current === 0 ? ongoingacts : comingacts).map((act) => (
            <div className="act" key={act.aid}>
              <div className="header">
                <div className="name">{act.name}</div>
                {countdowns[act.month] ? (
                  <div className="countdown">
                    <div className="cddesc">距结束：</div>
                    <div className="val dd">{countdowns[act.month].dpart}</div>
                    <div className="des">天</div>
                    <div className="val hh">{countdowns[act.month].hpart}</div>
                    <div className="des">时</div>
                    <div className="val mm">{countdowns[act.month].mpart}</div>
                    <div className="des">分</div>
                  </div>
                ) : null}
              </div>
              <div className="body">
                <img className="img" alt={act.name} src={act.headurl}></img>
              </div>
            </div>
          ))}
        </div>
        <div className="spliter"></div>
        <div className="footer">
          <div className="logoname">
            <img className="logo" alt="欧洲臻品特卖会" src={logo}></img>
            <div className="sitename">欧洲臻品</div>
          </div>
          <div className="contact">
            <div className="head">客服服务：</div>
            <div className="body">
              客服中心联系方式： <br />
              邮件：info@europass.paris
            </div>
          </div>
          <div className="service">
            周一到周五：
            <br />
            16:30 - 01:00（北京时间）
            <br />
            09:30 - 18:00（法国时间）
            <br />
            <br />
            周末：
            <br />
            17:00 - 24:00（北京时间）
            <br />
            10:00 - 17:00（法国时间）
            <br />
          </div>
          <div className="qrcode">
            <img alt="二维码" className="code" src={qrcode}></img>
            <div className="qrguide">
              扫码关注“游购欧洲通”公众号
              <br />
              了解更多欧洲旅游及海淘优惠
            </div>
          </div>
          <div className="qrcode">
            <img alt="二维码" className="code" src={qrcode}></img>
            <div className="qrguide">
              扫码关注“EuroPass欧洲通”微博
              <br />
              了解更多欧洲旅游及海淘优惠
            </div>
          </div>
          <div className="copyright">
            &copy; 2020 EUROPASS SAS. All Rights Reserved.
          </div>
        </div>
      </div>
    );
  }
}

export default App;
